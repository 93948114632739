@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900');
body {
  font-family: "Montserrat";
  /*background-image:url("../images/uucargo_background.png")*/
}

a:hover {
    cursor:pointer;
   }

:root {
    --main-radius: 5px;
    --main-padding: 5px;
  }

  .quote-container {
    display: grid;
    grid-template-columns: 500px auto ;
    grid-template-rows: 0.6r 0.6fr 0.6fr 0.6fr 0.6fr;
    grid-template-areas:
    "quote-content1 quote-content2"
    "quote-content3 quote-content2"
    "quote-content4 quote-content2"
    "quote-content5 quote-content2"
    "quote-content6 quote-content8"
    "quote-content7 quote-content8";
    grid-gap: 0.5rem;
    border-radius: 25px;
    justify-content: center;

  }

  @media only screen and (max-width: 550px) {
    .quote-container {
      grid-template-columns: 1fr ;
      grid-template-rows: 0.4fr auto ;
      grid-template-areas:
        "quote-content1"
        "quote-content2"
        "quote-content3"
        "quote-content4"
        "quote-content5"
        "quote-content6"
        "quote-content7"
        "quote-content8"
    }
  }

  #blank {
      grid-area: blank;
  }

  #side {
      grid-area: side;
  }

  #quote-content1 {
    border-radius: 25px;
    grid-area: quote-content1;
    color:orange;

  }

  #quote-content2 {
    border-radius: 25px;
    grid-area: quote-content2;
    text-align: center;

  }

  #quote-content3{
    grid-area: quote-content3;
    border-radius: 25px;

  }

  #quote-content4 {
    border-radius: 25px;
    grid-area: quote-content4;
  }

  #quote-content5 {
    grid-area: quote-content5;
    border-radius: 25px;
  }

  #quote-contentt6 {
    grid-area: quote-content6;
    border-radius: 25px;
  }

  #quote-content7 {
    grid-area: quote-content7;
    border-radius: 25px;
  }

  #quote-content8 {
    grid-area: quote-content8;
    border-radius: 25px;
    text-align: center;
  }

  #iconbuttonarrow {
      background-color:rgb(30, 100, 30);
  }

  #button1{
    color:green;
  }

  #button2{
    color: green;
  }

  #crendentials1{
    color:green;
  }

  #sidebar {
    grid-area: sidebar
  }

  #main {
    grid-area : main
  }

  .signUpContainer {
    display: grid;
    grid-template-columns: 1fr ;
    grid-gap: 0.5rem;
    border-radius: 25px;
    width: 400px;
  }

  .signUpButton {
    border-radius: 20px;

  }

  .textfield {
    border-radius: 25px;
    background-color: white;
  }

.AppBar {
  margin-top: 0px;
  background-color: white;
}

.title {
    font-size: 18pt;
    color: orange;
    font-family: "Montserrat";
    font-weight: 600;
}

.subtitle {
    font-size: 18pt;
    color: darkgreen;
    font-family: "Montserrat";
    font-weight: 600;
}


.bodytext {
    font-size: 16pt;
    color: seagreen;
    font-family: "Montserrat";
}

.navButton {
    border-color: green;
    outline: 2px solid green;
}

.userForm{
    border-radius: 20px;
    border: 2px solid black;
    padding: 20px;
    max-width: 50%;
}

.jumboStyle {
    color: "black";
    height: "100vh";
    font-family: "Montserrat";
    font-size: "1vw";
    letter-spacing: "2px";
    display: "flex";
    align-items: "center";
    flex-direction: "column";
    justify-content: "center";
    text-align: "center";
    overflow: "hidden";
}


/*button ~ button {*/
/*  margin-left: 20px;*/
/*}*/


